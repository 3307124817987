@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif ;
}

.container {
  @apply px-24 xl:px-16 lg:px-10 md:px-6 xs:px-3; 
}

html {
  scroll-behavior: smooth;
}